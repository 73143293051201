<template>
    <content-wrapper
        :title="$trans('Tests')"
    >
        <template v-if="isMobile" #actions>
            <w-actions-menu
                icon="MENU"
                :items="menu"
            />
        </template>

        <template #default>
            <w-toolbar-menu
                v-if="!isMobile"
                :items="menu"
                height="52"
                divider
                underlined
                outlined
            />
            <div class="mt-4">
                <router-view></router-view>
            </div>
        </template>
    </content-wrapper>
</template>

<script>
/* eslint-disable */
import ContentWrapper from '@/components/ContentWrapper'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'QuestionsStudentPage',
    mixins: [ mobileBreakpointChecker ],
    components: { ContentWrapper },
    computed: {
        menu() {
            return [
                {
                    text: this.$trans('Tests'),
                    icon: 'QUESTIONS',
                    to: {
                        name: 'questions.active',
                    }
                },
                {
                    text: this.$trans('Results of tests'),
                    icon: 'CHECK',
                    to: {
                        name: 'questions.results'
                    }
                }
            ]
        }
    }
}
</script>

<style lang=scss>

</style>
